// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('trix')
require('@rails/actiontext')
require('channels');
require('jquery');
require('bootstrap');
require('svg-injector');

import SVGInjector from 'svg-injector'
import 'bootstrap';
import 'font-awesome/css/font-awesome.css';

window.jQuery = $;
window.$ = $;
window.SVGInjector = SVGInjector

import '../js/plugins/slick.min'
import '../js/plugins/jquery.magnific-popup.min'
import '../js/plugins/jquery.meanmenu.min'
import '../js/plugins/isotope.pkgd.min'
import '../js/main'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

import "../stylesheets/main.scss"
